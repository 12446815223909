<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-7">
        <div class="card bg-secondary card-body shadow">
          <div class="row justify-content-center mb-3 mt-2">
            <img src="/img/brand/logo.png" style="height: 70px" alt="..." 
              class="c-pointer"
              @click="$r.changeRoute('/home')"
            />
          </div>

          <div class="text-center text-muted mb-4">
            <small>{{
              !forgotToken
                ? $t.translate("TTL_ENTER_EMAIL_ADDRESS")
                : $t.translate("TTL_RESET_PASSWORD")
            }}</small>
          </div>

          <template v-if="!forgotToken">
            <base-input
              v-model="formData.Email"
              formClasses="input-group-alternative"
              :placeholder="$t.translate('FRM_EMAIL')"
              addon-left-icon="fas fa-envelope"
            >
            </base-input>

            <div class="text-center">
              <el-button
                type="primary"
                class="mt-3 mb-3 f-btn-common f-btn-orange"
                @click="sendLink()"
                >{{ $t.translate("BTN_SEND_RESET_LINK") }}</el-button
              >
            </div>
          </template>

          <template v-else>
            <base-input
              v-model="formData.NewPassword"
              formClasses="input-group-alternative mb-3 mt-2"
              :placeholder="$t.translate('FRM_NEW_PASSWORD')"
              type="password"
              :reveal="true"
              addon-left-icon="ni ni-lock-circle-open"
            >
            </base-input>
            <base-input
              v-model="formData.ConfirmPassword"
              formClasses="input-group-alternative mb-3"
              :placeholder="$t.translate('FRM_CONFIRM_PASSWORD')"
              type="password"
              :reveal="true"
              addon-left-icon="ni ni-lock-circle-open"
            >
            </base-input>

            <el-alert
              :closable="false"
              :title="$t.translate('MSG_ERR_PASSWORD_INVALID')"
              type="warning"
              class="custom-alert mb-3"
            />

            <div class="text-center">
              <el-button
                type="primary"
                class="mt-3 mb-2 f-btn-common f-btn-orange"
                @click="resetPassword()"
                >{{ $t.translate("BTN_CONFIRM") }}</el-button
              >
            </div>
          </template>
        </div>

        <div v-if="!forgotToken" class="my-3">
          <a
            class="h5 text-muted"
            href="javascript:void(0)"
            @click="$r.changeRoute('/login')"
            ><i class="fas fa-chevron-left mr-2"></i
            >{{ $t.translate("BTN_BACK_TO_LOGIN") }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, reactive } from "vue";
import { useRoute } from "vue-router";
import { post, get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { router } from "@/utils/router";
import { store } from "@/store";

export default {
  setup() {
    const route = useRoute();
    let forgotToken = ref(null);
    let formData = reactive({ Email: "", NewPassword: "", ConfirmPassword: "" });

    onMounted(async () => {
      await store.dispatch("user/resetData"); //reset data

      if (route?.query?.token) {
        let res = await get("/user/forgot", { Token: route.query.token });
        if (!isValidResponse(res)) {
          router.replaceRoute("/forgot");
        } else {
          forgotToken.value = route.query.token;
        }
      }
    });

    const sendLink = async () => {
      let res = await post("/user/forgot", { Email: formData.Email });
      if (!isValidResponse(res)) return;
      //send message
      formData.Email = ""; //reset to empty
      helpers.showMessage({ Code: "MSG_RESET_EMAIL_SENT", Message: "Reset email sent!" });
    };

    const resetPassword = async () => {
      formData.Token = forgotToken.value;
      let res = await post("/user/reset", formData);
      if (isValidResponse(res)) {
        await store.dispatch("page/setData", { isPasswordSet: true });
        router.replaceRoute("/login");
      }
    };

    return { forgotToken, resetPassword, formData, sendLink };
  },
};
</script>
<style></style>
